import classNames from 'classnames';
import React from 'react';

const BackGroundFigures = ({ className }) => {
  const classes = classNames('back-ground-figures', className ? ' ' + className : '');

  return (
    <div className={classes}>
      <div className="back-ground-figure__1"></div>
      <div className="back-ground-figure__2"></div>
      <div className="back-ground-figure__3"></div>
    </div>
  );
};

export default BackGroundFigures;
