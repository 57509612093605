import 'core-js/stable';
import { injectStores } from '@mobx-devtools/tools';
import { render } from '@optimistdigital/create-frontend/universal-react/client';
import * as Sentry from '@sentry/react';
import App from 'app/App.js';
import { createStore } from 'app/mobx';
import { getRoutes, isProduction } from './helpers/utils';

function hydrate() {
  Sentry.init({
    dsn: 'https://36f4423a473b09756964e877db6589fb@o4506583504322560.ingest.sentry.io/4506583644307456',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^https:\/\/api\.eglibyte\.com/, /^https:\/\/api\.dev\.eglibyte\.com/],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  const store = createStore();

  if (!isProduction) {
    console.info('Initial store:', window.__OCF_APP_DATA__.pageData?.store);
    injectStores(store);
  }

  render(App, document.getElementById('react-app'), {
    routes: getRoutes(),
    store,
  });
}

hydrate();

/**
 * Hot module replacement
 * Uncomment the code to enable HMR in your app. Documentation:
 * https://webpack.js.org/api/hot-module-replacement/
 */
if (module.hot) {
  module.hot.accept(['app/App.js', './mobx', 'app/helpers/utils.js', 'app/helpers/log.js'], () => {
    hydrate();
  });
}
