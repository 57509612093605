import api from 'app/api';

export const fetchInitialContent = async (cache = 0) => {
  const res = await api().get('/content/initial', void 0, { cache });
  return res.data || null;
};

export const fetchPageData = async (path) => {
  if (path == null) {
    console.error('No path passed to fetchPageData:', path);
    return null;
  }

  return await api().get(`/content/page?path=${path}`);
};

export const fetchCourseAuthors = async () => {
  const res = await api().get(`/authors`);
  return res.data || [];
};

export const fetchCourseAuthor = async (id) => {
  const res = await api().get(`/authors/${id}`);
  return res.data || [];
};

export const fetchCourses = async () => {
  const res = await api().get(`/courses`);
  return res.data || [];
};

export const fetchCourse = async (id) => {
  const res = await api().get(`/courses/${id}`);
  return res.data || [];
};

export const fetchCookiesFields = async () => {
  const res = await api().get(`/content/cookies`);
  return res.data || [];
};

export const sendFeedback = async (data) => {
  const res = await api().post(`/contactRequest`, data);
  return res.data || [];
};
