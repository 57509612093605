import BlogPage from 'app/pages/BlogPage';
import CoursePage from 'app/pages/CoursePage';
import CoursesPage from 'app/pages/CoursesPage';
import EpisodePage from 'app/pages/EpisodePage';
import HomePage from 'app/pages/HomePage';
import BlogPostPage from 'app/pages/PostPage';

export default {
  'home-page': HomePage,
  'courses-page': CoursesPage,
  'course-page': CoursePage,
  'episode-page': EpisodePage,
  'blog-page': BlogPage,
  'blog-post-page': BlogPostPage,
};
