import React from 'react';
import classNames from "classnames";
import {CourseSectionBlock} from "../index";

const CourseSectionList = ({className}) => {
  
  const classes = classNames('course-section-list', (className ? ' ' + className : ''));
  
  return (
    <div className={classes}>
      <CourseSectionBlock/>
      <CourseSectionBlock open/>
      <CourseSectionBlock/>
      <CourseSectionBlock />
    </div>
  );
};

export default CourseSectionList;
