import React from 'react';

export default function EpisodePage() {
  return <div>EpisodePage</div>;
}

EpisodePage.getPageData = async (/* location, params, props */) => {
  return prevState => ({
    ...prevState,
    // Add data here that will be added whenever the user navigates to this page
  });
};
