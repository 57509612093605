import { useAppData } from '@optimistdigital/create-frontend/universal-react';
import useMobx from 'app/mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Helmet } from 'react-helmet-async';

function SeoTags() {
  const appData = useAppData();

  const {
    content: { seo },
  } = useMobx();

  const url = `https://eglibyte.com${appData.url}`;

  return (
    <Helmet>
      {/* Basic SEO Info */}
      <meta property="title" content={seo.title} />
      <meta name="description" content={seo.description} />
      <meta name="keywords" content={seo.keywords} />

      {/* Canonical URL */}
      {!!seo.canonical && <link rel="canonical" href={seo.canonical} />}

      {/* Page title */}
      <title>{seo.title}</title>

      {/* #region OpenGraph and Rich Embeds */}
      <meta property="og:site_name" content={'Eglibyte'} />
      <meta property="og:title" content={seo['og:title']} />
      <meta property="og:description" content={seo['og:description']} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content={seo['og:type']} />
      <meta property="og:image" content={seo['og:image']} />

      {/* TODO: How to implement this on server? */}
      {/* <meta property="og:image:width" content="1280" /> */}
      {/* <meta property="og:image:height" content="640" /> */}

      <meta property="og:image:alt" content={seo.imageAlt} />
      <meta property="og:locale" content="ru_RU" />
      {/* <meta property="og:locale:alternate" content="et_EE" />
      <meta property="og:locale:alternate" content="en_US" /> */}

      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:image:alt" content={seo.imageAlt} />
      {/* #endregion OpenGraph and Rich Embeds */}

      {/* #region Rich Results */}
      {!!seo.jsonLd && React.createElement('script', { type: 'application/ld+json' }, JSON.stringify(seo.jsonLd))}
      {/* #endregion Rich Results */}
    </Helmet>
  );
}

export default observer(SeoTags);
