import React from 'react';
import classNames from "classnames";
import {ContentPreview} from "../index";

const ContentPreviewListMiddle = ({className, content}) => {
  
  const lgClasses = classNames('content-preview-list-middle', 'content-preview-list-middle__lg', (className ? ' ' + className : ''));
  const mdClasses = classNames('content-preview-list-middle', 'content-preview-list-middle__md', (className ? ' ' + className : ''));
  const smClasses = classNames('content-preview-list-middle', 'content-preview-list-middle__sm', (className ? ' ' + className : ''));
  
  return (
    <>
      <div className={lgClasses}>
        {content.map((el, index) => (
          <ContentPreview key={el.id} content={el} size={index % 2 === 0 ? 'horizontal' : 'small'} />
        ))}
      </div>
      
      <div className={mdClasses}>
        {content.map(el => <ContentPreview key={el.id} content={el} size='horizontal'/>)}
      </div>
      
      <div className={smClasses}>
        {content.map(el => <ContentPreview key={el.id} content={el} size='vertical'/>)}
      </div>
    </>
  );
};

export default ContentPreviewListMiddle;
