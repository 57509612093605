import React from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

const TagList = ({ className, tags }) => {
  if (!tags || tags.length === 0) {
    return null;
  }
  
  const classes = classNames('tag-list', className);
  
  return (
    <ul className={classes}>
      {tags.map((tag, index) => (
        <li key={index} className="tag-list__tag">
          <NavLink to={`#${tag}`}>{tag}</NavLink>
        </li>
      ))}
    </ul>
  );
};

export default TagList;
