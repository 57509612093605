import templates from 'app/config/templates';
import { matchPath } from 'react-router';
import config from 'server/config';

export const isProduction = getAppConfig()?.isProduction;

export function getAppConfig() {
  if (typeof window === 'undefined') return config();
  return window.__OCF_APP_DATA__.pageData?.config || {};
}

export function getRoutes() {
  if (typeof window === 'undefined') return [];
  return window.__OCF_APP_DATA__.pageData?.routes || [];
}

export function getAppStore() {
  if (typeof window === 'undefined') return {};
  return window.__OCF_APP_DATA__.pageData?.store || {};
}

export const FORMIK_KEYS_TO_REMOVE = ['initialTouched', 'initialValue', 'initialError'];

export async function getRouteData(location, routes, backendData) {
  const route = routes.find((x) => matchPath(location.pathname, { exact: true, ...x }));

  let updater;

  if (route && route.component?.getPageData) {
    updater = await route.component.getPageData(location, matchPath(location.pathname, route).params, backendData);
  } else if (templates[route?.template]?.getPageData) {
    updater = await templates[route?.template]?.getPageData(
      location,
      matchPath(location.pathname, route).params,
      backendData
    );
  }

  return updater || ((x) => x);
}

export function createRoutes(cmsRoutes = []) {
  const routes = [];

  cmsRoutes.forEach((route) => {
    routes.push(route);

    if (route.children) {
      route.children.forEach((child) => routes.push(child));
    }
  });

  return routes;
}

/**
 * @returns path with express style dynamic slugs resolved according to the given params
 */
export function compilePath(path, params) {
  let output = path;

  Object.entries(params).forEach(([slug, value]) => {
    output = output.replace(`:${slug}`, value);
  });

  return output;
}
