import { Article, PublicationDate, TagList, ViewsCount } from 'app/components';
import classNames from 'classnames';
import React from 'react';

const PostArticle = ({ className, post }) => {
  const classes = classNames('post-article-section', className ? ' ' + className : '');

  return (
    <section className={classes}>
      <div className="container">
        <div className="post-article">
          <div className="post-article__header">
            <TagList tags={post.tags} className="post-article__tags" />
            <ul className="post-article__data">
              <li className="post-article__point">
                <PublicationDate date={post.updated_at || post.created_at} />
              </li>
              <li className="post-article__point">
                <ViewsCount count={post.views} />
              </li>
            </ul>
          </div>

          <Article post={post} />
        </div>
      </div>
    </section>
  );
};

export default PostArticle;
