import { hydrateStore } from 'app/helpers/hydrate';
import React from 'react';
import { initFromCookie } from './persist';
import stores from './stores/index';

// Default keys in store classes that will be ignored in server sided serialization
const ignoreKeys = ['ignoreKeys'];

const StoreContext = React.createContext(null);

export const MobxStoreProvider = ({ store, children }) => {
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};

export default function useMobx() {
  const store = React.useContext(StoreContext);
  if (!store) throw new Error('Store is not initialized!');
  return store;
}

export const createStore = (cookies) => {
  const mobxStore = {};

  // Instantiate stores
  for (const storeKey in stores) {
    const hydrateStoreWrapper = (storeSlice, keysToIgnore = []) => {
      return hydrateStore(storeSlice, storeKey, [...ignoreKeys, ...keysToIgnore]);
    };

    mobxStore[storeKey] = new stores[storeKey](hydrateStoreWrapper);
    mobxStore[storeKey].getMobx = () => mobxStore; // Add method to access root store
    mobxStore[storeKey] = initFromCookie(storeKey, mobxStore[storeKey], cookies);
  }

  return mobxStore;
};
