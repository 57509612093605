import React from 'react';
import classNames from "classnames";
import {BookSolidSvg, ClockSolidSvg, LevelSvg} from "app/svg";

const CourseData = ({className}) => {
  
  const classes = classNames('course-data', (className ? ' ' + className : ''));
  
  return (
    <ul className={classes}>
      <li className="course-data__point">
        <BookSolidSvg/>
        <span>
          25 Эпизодов
        </span>
      </li>
      <li className="course-data__point">
        <ClockSolidSvg/>
        <span>
          3ч 43м
        </span>
      </li>
      <li className="course-data__point">
        <LevelSvg/>
        <span>
          Сложность
        </span>
      </li>
    </ul>
  );
};

export default CourseData;
