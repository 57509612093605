import classNames from 'classnames';
import React from 'react';

const formatDate = (isoDate) => {
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  return new Date(isoDate).toLocaleDateString('ru-RU', options);
};

const PublicationDate = ({ className, date }) => {
  if (!date) return null;

  const formattedDate = formatDate(date);
  const classes = classNames('publication-date', className ? ' ' + className : '');

  return <span className={classes}>{formattedDate}</span>;
};

export default PublicationDate;
