import React from 'react';
import classNames from "classnames";

const GrayLogoSvg = ({className}) => {
  
  const classes = classNames('gray-logo-svg', (className ? ' ' + className : ''));
  
  return (
    <svg className={classes} width="512" height="512" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M243.542 459.576L248.924 459.543L249.038 458.501L258.437 390.566L258.795 387.976L226.614 383.367L226.24 385.957L216.191 458.501L216.077 459.25L243.542 459.576Z" fill="url(#paint0_linear_340_55)"/>
      <path d="M237.608 321.687L75.2754 298.071L257.122 246.033L410.349 266.522C410.349 266.522 402.218 271.246 400.218 272.223C361.826 290.497 237.608 321.687 237.608 321.687Z" fill="url(#paint1_linear_340_55)"/>
      <path d="M342.719 108.666L187.882 172.577L314.832 191.031L419.552 150.589C433.715 145.703 433.634 121.435 433.634 121.435L342.719 108.666Z" fill="url(#paint2_linear_340_55)"/>
      <path d="M424.608 475H89.6328C89.8457 470.611 91.7354 466.472 94.9107 463.44C98.086 460.409 102.304 458.716 106.691 458.713H407.518C411.912 458.704 416.14 460.391 419.323 463.425C422.506 466.458 424.399 470.604 424.608 475Z" fill="url(#paint3_linear_340_55)"/>
      <path d="M426.104 379.312C425.981 383.404 424.987 387.423 423.189 391.099C421.391 394.775 418.83 398.025 415.678 400.631C412.526 403.236 408.854 405.138 404.909 406.208C400.964 407.279 396.836 407.493 392.802 406.838L262.389 388.482L260.763 388.254L231.786 384.166L230.16 383.938L135.472 370.615C103.178 366.005 77.6483 337.845 75.2092 305.742C75.0466 303.543 74.9653 300.205 75.0141 297.973H78.0874L230.095 319.375L233.932 319.912L395.924 342.714C404.546 344.077 412.373 348.549 417.932 355.29C423.491 362.031 426.397 370.574 426.104 379.312Z" fill="url(#paint4_linear_340_55)"/>
      <path d="M436.984 239.062C436.852 243.147 435.863 247.157 434.081 250.834C432.299 254.51 429.765 257.769 426.642 260.399C425.512 261.351 424.305 262.206 423.032 262.956C421.687 263.781 420.266 264.474 418.788 265.024C414.015 266.831 408.861 267.392 403.812 266.653L266.016 247.809L262.195 247.287L248.65 245.479C214.843 240.886 188.289 210.299 187.882 176.34C187.882 175.118 188.077 173.962 188.126 172.724H188.386L314.035 189.907L317.287 190.347L406.722 202.562C415.342 203.905 423.175 208.355 428.748 215.077C434.321 221.799 437.25 230.329 436.984 239.062Z" fill="url(#paint5_linear_340_55)"/>
      <path d="M433.568 121.533H433.324L344.279 108.975L341.027 108.519L290.927 101.467C282.587 100.153 274.979 95.9267 269.45 89.5361C263.921 83.1455 260.826 75.0022 260.714 66.5465C260.714 65.9765 260.714 65.4227 260.714 64.9178C260.854 60.8125 261.859 56.7833 263.664 53.0948C265.469 49.4063 268.032 46.142 271.186 43.5162C276.385 39.2436 282.918 36.9375 289.642 37.0013C291.133 37.005 292.622 37.1139 294.098 37.327L373.158 48.4024C401.517 52.4417 424.608 74.6576 431.551 101.743C433.198 108.203 433.878 114.873 433.568 121.533Z" fill="url(#paint6_linear_340_55)"/>
      <defs>
        <linearGradient id="paint0_linear_340_55" x1="216.085" y1="421.471" x2="258.797" y2="421.471" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F3EEF2" stopOpacity="0.55"/>
          <stop offset="1" stopColor="#A7A8AA" stopOpacity="0.52"/>
        </linearGradient>
        <linearGradient id="paint1_linear_340_55" x1="75.3405" y1="283.86" x2="410.365" y2="283.86" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F3EEF2" stopOpacity="0.55"/>
          <stop offset="1" stopColor="#A7A8AA" stopOpacity="0.52"/>
        </linearGradient>
        <linearGradient id="paint2_linear_340_55" x1="187.93" y1="149.848" x2="433.646" y2="149.848" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F3EEF2" stopOpacity="0.55"/>
          <stop offset="1" stopColor="#A7A8AA" stopOpacity="0.52"/>
        </linearGradient>
        <linearGradient id="paint3_linear_340_55" x1="89.6979" y1="466.856" x2="424.625" y2="466.856" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F3EEF2" stopOpacity="0.55"/>
          <stop offset="1" stopColor="#A7A8AA" stopOpacity="0.52"/>
        </linearGradient>
        <linearGradient id="paint4_linear_340_55" x1="75.0682" y1="352.591" x2="426.142" y2="352.591" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F3EEF2" stopOpacity="0.55"/>
          <stop offset="1" stopColor="#A7A8AA" stopOpacity="0.52"/>
        </linearGradient>
        <linearGradient id="paint5_linear_340_55" x1="187.931" y1="219.847" x2="437.013" y2="219.847" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F3EEF2" stopOpacity="0.55"/>
          <stop offset="1" stopColor="#A7A8AA" stopOpacity="0.52"/>
        </linearGradient>
        <linearGradient id="paint6_linear_340_55" x1="260.747" y1="79.2664" x2="433.649" y2="79.2664" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F3EEF2" stopOpacity="0.55"/>
          <stop offset="1" stopColor="#A7A8AA" stopOpacity="0.52"/>
        </linearGradient>
      </defs>
    </svg>
  
  );
};

export default GrayLogoSvg;