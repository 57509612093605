import usePageData from 'app/hooks/usePageData';
import { Button } from 'app/ui';
import classNames from 'classnames';
import React, { useCallback } from 'react';

export default function Offer({ className }) {
  const { page: { data } = {}, content } = usePageData();

  const classes = classNames('offer-section', className ? ' ' + className : '');

  const scroll = useCallback(() => {
    const section = document.querySelector('#contact');
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, []);

  return (
    <section className={classes}>
      <div className="container">
        <div className="offer">
          <h1 className="offer__title">Обучение программированию.</h1>
          <p className="offer__description">
            <span className="offer__description-point">
              <span className="offer__description-text">Учись</span>
              <span className="offer__description-emoji">🎓</span>
            </span>

            <span className="offer__description-point">
              <span className="offer__description-text">Работай</span>
              <span className="offer__description-emoji">💻</span>
            </span>

            <span className="offer__description-point">
              <span className="offer__description-text">Развивайся</span>
              <span className="offer__description-emoji">🚀</span>
            </span>
          </p>
          <div className="offer__footer">
            <Button onClick={scroll} className="offer__button" text="Начать бесплатно" />
            <span className="offer__footer-text">Образовательная платформа твоего будущего</span>
          </div>
        </div>
      </div>
    </section>
  );
}
