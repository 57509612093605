import React from 'react';
const WhatsUpSvg = (props) => {
  const {className} = props;
  
  const classes = `whats-up-svg${className ? ' ' + className : ''}`;
  
  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" version="1.1"
         width="512" height="512" x="0" y="0" viewBox="0 0 512 512">
      <path
        d="M363.815 295.401c-2.535-1.426-5.408-3.043-8.87-5.126-1.932-1.167-4.361-2.762-6.935-4.451-13.212-8.672-23.223-14.81-31.745-14.81-1.996 0-3.884.338-5.605 1.002-7.466 2.875-13.597 10.556-19.526 17.983-2.173 2.721-5.283 6.617-7.17 8.358-7.486-1.361-23.275-11.142-40.313-28.18-17.058-17.058-26.841-32.865-28.184-40.333 1.739-1.894 5.665-5.027 8.403-7.214 7.399-5.907 15.05-12.015 17.941-19.458 3.688-9.563-3.071-21-13.806-37.354-1.689-2.574-3.285-5.005-4.443-6.922-2.091-3.475-3.707-6.35-5.133-8.884-5.853-10.402-9.72-17.277-24.251-17.277-9.431 0-22.765 8.912-31.797 17.75-13.126 12.847-20.356 27.679-20.356 41.793.025 38.334 21.419 82.27 58.697 120.586.064.064.128.081.194.145 38.316 37.279 82.276 58.6 120.609 58.6h.015c14.095 0 28.932-7.147 41.778-20.266 8.845-9.031 17.769-22.268 17.769-31.693.001-14.529-6.87-18.395-17.272-24.249zm-42.286 56.403c-32.652-.024-72.483-19.812-106.567-52.935-33.127-34.088-52.915-73.922-52.936-106.571 0-19.749 25.628-38.526 32.33-39.555 1.369.006 2.087.087 2.429.145.886 1.02 2.647 4.15 4.215 6.937 1.419 2.522 3.186 5.662 5.436 9.401 1.36 2.253 3.056 4.836 4.851 7.57 3.397 5.176 10.226 15.58 11.377 20.021-1.925 2.725-7.898 7.493-11.27 10.185-8.631 6.891-16.085 12.841-16.085 21.25.021 20.312 26.184 48.052 34.202 56.07s35.758 34.181 56.113 34.201c8.355-.027 14.28-7.45 21.142-16.045 2.706-3.389 7.503-9.398 10.239-11.313 4.436 1.143 14.853 7.98 20.033 11.381 2.734 1.794 5.316 3.489 7.583 4.858 3.726 2.242 6.865 4.009 9.388 5.428 2.786 1.568 5.917 3.33 6.937 4.216.058.341.138 1.059.145 2.427-1.037 6.696-19.82 32.305-39.562 32.329z"/>
      <path
        d="M460.384 256.957c-.702-54.041-22.394-105.107-61.078-143.792-39.398-39.398-91.403-61.096-146.437-61.096-53.125 0-102.823 20.442-139.941 57.559-32.938 32.938-53.146 76.868-56.905 123.699-3.565 44.432 7.746 89.396 31.899 127.202L54.94 449.681a10 10 0 0 0 9.381 13.47c1.316 0 2.644-.381 3.903-.916l85.899-36.554c32.792 20.41 70.288 30.929 108.79 30.929h.004c53.116 0 102.813-20.198 139.926-57.311 37.808-37.809 58.244-88.204 57.541-142.342zM388.699 385.4c-33.338 33.338-78.01 51.697-125.787 51.696-36.373-.001-71.761-10.647-102.339-30.789a9.996 9.996 0 0 0-9.405-.854l-69.436 29.45 26.7-72.171a10.003 10.003 0 0 0-1.09-9.064c-49.076-72.705-40.779-169.391 19.728-229.897 33.34-33.34 78.016-51.702 125.799-51.702 49.691 0 96.674 19.617 132.294 55.238 34.977 34.976 54.588 81.112 55.222 129.91.633 48.698-17.723 94.221-51.686 128.183z"/>
    </svg>
  );
};

export default WhatsUpSvg;