import React from 'react';

const LogoSvg = (props) => {
  const {className} = props;
  
  const classes = `logo${className ? ' ' + className : ''}`;
  
  return (
    <svg className={classes} width="42" height="50" viewBox="0 0 42 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.2713 48.2393L19.8867 48.2355L19.8997 48.1165L20.9744 40.3615L21.0153 40.0659L17.3357 39.5397L17.293 39.8353L16.1439 48.1165L16.1309 48.2021L19.2713 48.2393Z"
        fill="#BC6CC2"/>
      <path
        d="M18.5926 32.4986L0.0313721 29.8026L20.8237 23.8622L38.3438 26.2012C38.3438 26.2012 37.4141 26.7404 37.1854 26.8519C32.7957 28.938 18.5926 32.4986 18.5926 32.4986Z"
        fill="#9361FF"/>
      <path
        d="M30.611 8.18103L12.9069 15.4769L27.4223 17.5834L39.3961 12.9668C41.0155 12.409 41.0062 9.63871 41.0062 9.63871L30.611 8.18103Z"
        fill="#9361FF"/>
      <path
        d="M39.9743 49.9999H1.6731C1.69744 49.4989 1.9135 49.0264 2.27657 48.6803C2.63963 48.3342 3.12188 48.141 3.62348 48.1406H38.0202C38.5226 48.1396 39.0061 48.3322 39.37 48.6785C39.734 49.0248 39.9504 49.4981 39.9743 49.9999Z"
        fill="url(#paint0_linear_276_455)"/>
      <path
        d="M40.1454 39.0768C40.1313 39.5439 40.0177 40.0026 39.8121 40.4223C39.6065 40.842 39.3137 41.2129 38.9532 41.5104C38.5928 41.8078 38.173 42.0249 37.7219 42.1471C37.2708 42.2693 36.7989 42.2938 36.3376 42.219L21.4261 40.1235L21.2402 40.0975L17.927 39.6308L17.741 39.6048L6.91442 38.0839C3.22189 37.5577 0.302812 34.343 0.023919 30.6784C0.00532619 30.4274 -0.00397022 30.0462 0.00160763 29.7915H0.353012L17.7336 32.2346L18.1724 32.296L36.6946 34.899C37.6804 35.0544 38.5754 35.565 39.211 36.3345C39.8466 37.104 40.1789 38.0793 40.1454 39.0768Z"
        fill="url(#paint1_linear_276_455)"/>
      <path
        d="M41.3893 23.0664C41.3742 23.5326 41.2611 23.9905 41.0574 24.4101C40.8536 24.8298 40.5638 25.2018 40.2068 25.5021C40.0776 25.6107 39.9395 25.7084 39.794 25.794C39.6402 25.8882 39.4777 25.9673 39.3087 26.0301C38.7629 26.2364 38.1736 26.3004 37.5963 26.216L21.8407 24.0648L21.4038 24.0053L19.855 23.799C15.9896 23.2746 12.9534 19.7829 12.9069 15.9063C12.9069 15.7668 12.9292 15.6348 12.9348 15.4935H12.9645L27.3312 17.4551L27.7031 17.5053L37.9291 18.8997C38.9147 19.053 39.8103 19.561 40.4476 20.3284C41.0848 21.0957 41.4197 22.0694 41.3893 23.0664Z"
        fill="url(#paint2_linear_276_455)"/>
      <path
        d="M40.9988 9.64983H40.9709L30.7895 8.21632L30.4176 8.16426L24.6892 7.35919C23.7355 7.20921 22.8657 6.72679 22.2335 5.99727C21.6013 5.26775 21.2475 4.33814 21.2346 3.37289C21.2346 3.30781 21.2346 3.2446 21.2346 3.18696C21.2506 2.71832 21.3656 2.25837 21.5719 1.83731C21.7783 1.41625 22.0714 1.04361 22.432 0.74386C23.0264 0.256118 23.7734 -0.00713256 24.5423 0.000147025C24.7128 0.000574296 24.883 0.0130002 25.0517 0.0373327L34.0916 1.30165C37.3342 1.76275 39.9743 4.29881 40.7683 7.3908C40.9565 8.12824 41.0342 8.88956 40.9988 9.64983Z"
        fill="url(#paint3_linear_276_455)"/>
      <defs>
        <linearGradient id="paint0_linear_276_455" x1="1.68053" y1="49.0703" x2="39.9762" y2="49.0703"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#D24CB1"/>
          <stop offset="1" stopColor="#98A1DF"/>
        </linearGradient>
        <linearGradient id="paint1_linear_276_455" x1="-0.000251657" y1="36.0201" x2="40.1491" y2="36.0201"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#D646AE"/>
          <stop offset="1" stopColor="#97A2DF"/>
        </linearGradient>
        <linearGradient id="paint2_linear_276_455" x1="2037" y1="1215.19" x2="6396.77" y2="1197.69"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#D646AE"/>
          <stop offset="1" stopColor="#97A2DF"/>
        </linearGradient>
        <linearGradient id="paint3_linear_276_455" x1="2318.8" y1="219.92" x2="4080.52" y2="409.083"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#D646AE"/>
          <stop offset="1" stopColor="#97A2DF"/>
        </linearGradient>
      </defs>
    </svg>
  
  );
};

export default LogoSvg;