import { BackGroundFigures, SeoTags } from 'app/components';
import { ContactFeedback, Footer, Header } from 'app/sections';
import { observer } from 'mobx-react';
import React from 'react';
import { Offer } from './sections';

function HomePage() {
  return (
    <>
      <SeoTags />

      <BackGroundFigures />
      <Header />
      <Offer />
      <ContactFeedback />
      <Footer />
    </>
  );
}

HomePage.getPageData = async (/* location, params, props */) => {
  return (prevState) => ({
    ...prevState,
    // Add data here that will be added whenever the user navigates to this page
  });
};

export default observer(HomePage);
