import EmailSvg from 'app/svg/EmailSvg';
import TelegramSvg from 'app/svg/TelegramSvg';
import WhatsUpSvg from 'app/svg/WhatsUpSvg';
import classNames from 'classnames';
import React from 'react';

export default function SocialList({ className }) {
  const classes = classNames('social-list', className ? ' ' + className : '');

  return (
    <ul className={classes}>
      <li className="social-list__point">
        <a href="https://t.me/tip_proger" className="social-list__link" aria-label="Telegram link">
          <TelegramSvg />
        </a>
      </li>
      <li className="social-list__point">
        <a href="#whatsapp" className="social-list__link" aria-label="Whatsapp link">
          <WhatsUpSvg />
        </a>
      </li>
      <li className="social-list__point">
        <a href="mailto:support@eglibyte.ee" className="social-list__link" aria-label="Email link">
          <EmailSvg />
        </a>
      </li>
    </ul>
  );
}
