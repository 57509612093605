import { SmallForm } from 'app/components';
import romanImage from 'app/images/roman-egliens.png';
import { Image } from 'app/ui';
import classNames from 'classnames';
import React from 'react';

export default function ContactFeedback({ className }) {
  const classes = classNames('contact-feedback-section', className ? ' ' + className : '');

  return (
    <section className={classes}>
      <div className="container">
        <div className="contact-feedback">
          <Image className="contact-feedback__image" src={romanImage} alt="Roman Egliens" />
          <div className="contact-feedback__information">
            <div className="contact-feedback__information-header">
              <h3 className="contact-feedback__title">Нужна помощь в выборе курса?</h3>
              <p className="contact-feedback__description">Заполни форму и мы свяжемся с тобой как можно скорее</p>
            </div>
            <div className="contact-feedback__information-footer">
              <h4 className="contact-feedback__name">Роман Эглиенс</h4>
              <p className="contact-feedback__name-subtitle">Помогу сделать тебе первые шаги</p>
              <p className="contact-feedback__name-description">
                Основатель Eglibyte School, с более чем 6 годами опыта в программировании и 4 годами менторства. Школа
                является центром, где студенты получают технические навыки и ценные знания под моим руководством.
              </p>
            </div>
          </div>
          <div className="contact-feedback__form">
            <SmallForm className="contact-feedback__small-form" />
          </div>
        </div>
      </div>
    </section>
  );
}
