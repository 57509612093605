import { BackGroundFigures, SeoTags } from 'app/components';
import { trimStringToLength } from 'app/helpers';
import { Course, Footer, Header } from 'app/sections';
import React from 'react';

const titleSuffix = ' - Eglibyte';

export default function CoursePage() {
  const seoTitle = trimStringToLength('{{ НАЗВАНИЕ КУРСА }}', 60 - titleSuffix.length) + titleSuffix;
  const seoDescription = trimStringToLength('{{ ОПИСАНИЕ КУРСА }}', 160);

  return (
    <>
      <SeoTags
        title={seoTitle}
        description={seoDescription}
        // includeJsonLd
        // customJsonLd={{
        //   '@context': 'https://schema.org',
        //   '@type': 'Course',
        //   name: '{{ НАЗВАНИЕ КУРСА }}',
        //   description: seoDescription,
        //   provider: {
        //     '@type': 'Organization',
        //     name: 'Eglibyte',
        //   },
        // }}
      />

      <BackGroundFigures />
      <Header />
      <Course />
      <Footer />
    </>
  );
}

CoursePage.getPageData = async (/* location, params, props */) => {
  return (prevState) => ({
    ...prevState,
    // Add data here that will be added whenever the user navigates to this page
  });
};
