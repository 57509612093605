import templates from '../config/templates';
import logWarning from './log';

function isValidEmail(email) {
  const regExp = /\S+@\S+\.\S+/;
  return regExp.test(email);
}

export async function validateCompanyForm(values, ignoreFields = []) {
  const errors = {};
  Object.keys(values).forEach((key) => {
    if (!ignoreFields.includes(key) && !values[key]) {
      errors[key] = 'error.field-is-required';
    }
  });

  if (!errors.zip_code && isNaN(values.zip_code)) errors.zip_code = 'error.invalid-zip-code';

  return errors;
}

export async function validateAccountInfoForm(values) {
  const errors = {};
  Object.keys(values).forEach((key) => {
    if (!values[key]) {
      errors[key] = 'error.field-is-required';
    }
  });

  if (!errors.password && !errors.confirm_password) {
    if (values.password !== values.confirm_password) {
      errors.confirm_password = 'error.password-confirmation-no-match';
    }
  }

  if (!errors.email && !isValidEmail(values.email)) errors.email = 'error.invalid-email';

  return errors;
}

export async function validateRequiredFields(values, ignoreFields = []) {
  const errors = {};
  Object.keys(values).forEach((key) => {
    if (!ignoreFields.includes(key) && !values[key]) {
      errors[key] = 'error.field-is-required';
    }
  });

  if (!ignoreFields.includes('email') && !errors.email && !isValidEmail(values.email)) {
    errors.email = 'error.invalid-email';
  }

  return errors;
}

export function isRouteValid(route, url, suppressWarnings = false) {
  let isValid = true;

  if (!route?.path) {
    if (!suppressWarnings) logWarning(`Found route with no path!`);
    isValid = false;
  }

  if (!route?.template && !route?.component && isValid) {
    if (!suppressWarnings && url) {
      logWarning(`
      Route match for ${url} was found but it's missing either 'template' or 'component' property!
    `);
    }
    if (!url) {
      logWarning(`
      Route ${route?.slug || route?.path} is missing either 'template' or 'component' property!
    `);
    }
    if (!suppressWarnings) logWarning(`Skipping page rendering!`);
    isValid = false;
  }

  if (route?.template && !templates?.[route.template] && isValid) {
    if (!suppressWarnings) {
      logWarning(
        `Route with key '${route.template}' was found but no corresponding template component was found from 'config/templates.js'!`
      );
    }

    isValid = false;
  }

  return isValid;
}
