import React, { useCallback } from 'react';
import { NavLink, useHistory } from 'react-router-dom';

export default function Button(props) {
  const { text, disabled, className, onClick, type = 'button', styleType = 'red', to = null } = props;

  const history = useHistory();

  const classes = `button${className ? ' ' + className : ''}${' ' + styleType}`;
  const ButtonComponent = to && to.startsWith('http') ? NavLink : 'button';
  const buttonProps = {
    disabled,
    className: classes,
    onClick: !to || (to && onClick) ? onClick : null,
    type,
    children: <span>{text}</span>,
  };

  const handleClick = useCallback(() => {
    onClick?.();
    history.push(to);
  }, [onClick, history, to]);

  return <ButtonComponent to={to} onClick={handleClick} {...buttonProps} />;
}
