import { Logo } from 'app/ui';
import classNames from 'classnames';
import React from 'react';

export default function Header({ className }) {
  const classes = classNames('header-section', className ? ' ' + className : '');

  return (
    <section className={classes}>
      <div className="container">
        <div className="header">
          <Logo />
        </div>
      </div>
    </section>
  );
}
