import React from 'react';
import classNames from "classnames";
import {EyeSvg} from "../../svg";

const ViewsCount = ({className, count}) => {
  
  const classes = classNames('views-count', (className ? ' ' + className : ''));
  
  return (
    <span className={classes}>
      <EyeSvg/>
      <span>{count}</span>
    </span>
  );
};

export default ViewsCount;
