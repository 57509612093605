import { usePageData } from 'app/hooks';
import { PostArticle, PostHeader } from 'app/pages/PostPage/sections';
import { observer } from 'mobx-react';
import React from 'react';

function PostPage() {
  // const content = {
  //   id: 1,
  //   tags: ['html', 'css'],
  //   title: 'Основы html 1',
  //   description:
  //     'Слова и буквы были заменены добавлением или сокращением элементов, поэтому будет совсем неразумно пытаться передать содержание; это не гениально. Слова и буквы были заменены добавлением или сокращением элементов, поэтому будет.',
  //   timestamp: 1668975307,
  //   views: 6505,
  //   author: 'Roman Egliens',
  //   url: '/post',
  //   image: {
  //     alt: 'Test alt',
  //     title: 'none',
  //     url: '#',
  //   },
  // };

  const {
    page: { data },
  } = usePageData();

  return (
    <>
      <PostHeader post={data.post} />
      <PostArticle post={data.post} />
    </>
  );
}

PostPage.getPageData = async (/* location, params, props */) => {
  return (prevState) => ({
    ...prevState,
    // Add data here that will be added whenever the user navigates to this page
  });
};

export default observer(PostPage);
