import { Image } from 'app/ui';
import classNames from 'classnames';
import React from 'react';

const PostHeader = ({ className, post }) => {
  const classes = classNames('post-header-section', className ? ' ' + className : '');

  return (
    <section className={classes}>
      <div className="container">
        <div className="post-header">
          <Image content={post.image} className="post-header__image" />
        </div>
      </div>
    </section>
  );
};

export default PostHeader;
