import { getAppConfig } from './utils'

const { isProduction = true, displayWarnings = false } = getAppConfig()

export default function logWarning(message, ...args) {
  if (typeof window !== 'undefined' && (!isProduction || displayWarnings)) {
    window.console.warn(message, ...args)
  } else if (typeof window === 'undefined' && (!isProduction || (isProduction && displayWarnings))) {
    console.warn(message, ...args)
  }
}
