import React from 'react';

const LevelSvg = () => {
  
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.38574" y="0.685547" width="4" height="16" rx="0.5" fill="#E7EAEF" stroke="#E7EAEF"/>
      <rect x="8.0022" y="0.685547" width="4" height="16" rx="0.5" fill="#E7EAEF" stroke="#E7EAEF"/>
      <rect x="14.6187" y="0.685547" width="4" height="16" rx="0.5" fill="#E7EAEF" stroke="#E7EAEF"/>
    </svg>
  );
};

export default LevelSvg;