import React from 'react';
import classNames from "classnames";
import {ContentPreviewListLarge, ContentPreviewListMiddle, ContentPreviewListSmall} from "app/components";

const ContentPreviews = ({className, content}) => {
  const classes = classNames('content-previews-section', className);
  
  const previewComponents = {
    small: ContentPreviewListSmall,
    middle: ContentPreviewListMiddle,
    large: ContentPreviewListLarge,
  };
  return (
    <section className={classes}>
      <div className="container">
        <div className="content-previews">
          {content?.map((block) => {
            const PreviewComponent = previewComponents[block.type] || ContentPreviewListSmall;
            return <React.Fragment key={block.id}><PreviewComponent content={block.content}/></React.Fragment>;
          })}
        </div>
      </div>
    </section>
  );
};

export default ContentPreviews;
