import React from 'react';

const EyeSvg = () => {
  
  return (
    <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M3.03397 2.54098C4.39498 1.44569 6.2096 0.491455 8.40082 0.491455C10.5921 0.491455 12.4067 1.44569 13.7677 2.54098C15.1281 3.63581 16.0661 4.89541 16.5182 5.57186L16.5182 5.57191C16.8245 6.03044 16.8245 6.61917 16.5182 7.0777L16.5182 7.07775C16.0661 7.75421 15.1281 9.01381 13.7677 10.1086C12.4067 11.2039 10.5921 12.1582 8.40082 12.1582C6.2096 12.1582 4.39498 11.2039 3.03397 10.1086C1.67352 9.01381 0.735508 7.75421 0.283507 7.07774C-0.0228877 6.61919 -0.0228877 6.03042 0.283507 5.57187C0.735508 4.89541 1.67352 3.63581 3.03397 2.54098ZM3.66092 3.32003C2.4089 4.32761 1.53615 5.49711 1.11498 6.12744C1.03329 6.24969 1.03329 6.39992 1.11498 6.52217C1.53615 7.15251 2.4089 8.32201 3.66092 9.32958C4.91238 10.3367 6.51196 11.1582 8.40082 11.1582C10.2897 11.1582 11.8893 10.3367 13.1407 9.32958C14.3927 8.32203 15.2655 7.15256 15.6867 6.52221C15.7683 6.39994 15.7683 6.24967 15.6867 6.1274C15.2655 5.49705 14.3927 4.32758 13.1407 3.32003C11.8893 2.31292 10.2897 1.49146 8.40082 1.49146C6.51196 1.49146 4.91238 2.31292 3.66092 3.32003Z"
            fill="#5E626B"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M8.40088 8.50342C9.60415 8.50342 10.5796 7.52798 10.5796 6.32471C10.5796 5.12144 9.60415 4.146 8.40088 4.146C7.19761 4.146 6.22217 5.12144 6.22217 6.32471C6.22217 7.52798 7.19761 8.50342 8.40088 8.50342ZM8.40088 9.50342C10.1564 9.50342 11.5796 8.08026 11.5796 6.32471C11.5796 4.56915 10.1564 3.146 8.40088 3.146C6.64533 3.146 5.22217 4.56915 5.22217 6.32471C5.22217 8.08026 6.64533 9.50342 8.40088 9.50342Z"
            fill="#5E626B"/>
    </svg>
  );
};

export default EyeSvg;