import {SocialList} from 'app/components';
import {Logo} from 'app/ui';
import classNames from 'classnames';
import React from 'react';

export default function Footer({className}) {
  const classes = classNames('footer-section', className ? ' ' + className : '');
  
  return (
    <>
      <div className="stickyFooter"/>
      <section className={classes}>
        <div className="container">
          <div className="footer">
            <div className="footer__wrapper">
              <Logo/>
            </div>
            <div className="footer__wrapper">
              <SocialList className="footer__social-list"/>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
