import { useAppData } from '@optimistdigital/create-frontend/universal-react';
import Router, { getRouteData } from '@optimistdigital/create-frontend/universal-react/Router';
import { DebugGrid, ErrorBoundary, SeoTags } from 'app/components';
import { isRouteValid } from 'app/helpers/validation';
import favicon16x16 from 'app/images/favicon-16x16.png';
import favicon32x32 from 'app/images/favicon-32x32.png';
import { MobxStoreProvider } from 'app/mobx';
import { hydrateInitialStore } from 'app/mobx/hydrateInitialStore';
import Pages from 'app/Pages';
import ErrorPage from 'app/pages/ErrorPage';
import 'app/scss/entry.scss';
import favicon from 'public/favicon.ico';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch } from 'react-router-dom';

export default function App({ store }) {
  const appData = useAppData();

  if (appData.pageData.error) return <ErrorPage status={appData.pageData.error} />;

  return (
    <React.StrictMode>
      <MobxStoreProvider store={store}>
        <Helmet>
          <link rel="icon" href={favicon} />
          <link rel="icon" type="image/png" sizes="32x32" href={favicon32x32} />
          <link rel="icon" type="image/png" sizes="16x16" href={favicon16x16} />

          <title>Eglibyte - школа программирования</title>

          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name="theme-color" content="#1c1c24" />
        </Helmet>
        <SeoTags />

        <ErrorBoundary renderError={() => <ErrorPage status={500} />}>
          <DebugGrid hide={1} big={0} />

          <Router>
            <Switch>
              <Pages />
              {/* <Cookies />*/}
            </Switch>
          </Router>
        </ErrorBoundary>
      </MobxStoreProvider>
    </React.StrictMode>
  );
}

/**
 * This function gets called once in the server, and in the client whenever the page changes.
 * The result ends up in the AppData.
 */
App.getPageData = async (location, { store, ...props }) => {
  try {
    // const [cookiesFields] = await Promise.all([fetchCookiesFields()]);
    await store.content.setLoading(true);
    // await store.content.setCookiesFields(cookiesFields);

    // Ask initial data only once on the server, or client if SSR failed
    if (__TARGET__ === 'node' || window.__OCF_APP_DATA__.skippedSSR) {
      await hydrateInitialStore(store);
    }

    // Merge static and dynamic routes
    const routes = store.content.routes.filter((route) => isRouteValid(route, location.pathname));

    // Fetch path based data from the CMS (nova-page-manager etc.)
    await store.content.fetchPageData(location.pathname);
    // Calls getPageData on the page that will be rendered, so it can fetch custom data if it needs to
    const updater = await getRouteData(location, routes, { store, ...props });

    await store.content.setLoading(false);

    return (prevState) => ({
      ...updater(prevState),
      config: prevState.config || props.config,
      url: prevState?.location?.pathname || location.pathname,
      store: prevState?.store || store,
      routes: prevState?.routes || routes,
    });
  } catch (error) {
    // In server, propagate the error so the frontend can take over
    if (__TARGET__ === 'node') throw error;

    return (prevState) => ({
      ...prevState,
      error: error.statusCode || 500,
    });
  }
};
