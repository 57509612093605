/**
 * This is the app config file. It's defined in the server, so you can access process.env.
 * In React, useAppData can be used to access this config in both the client and server.
 */
module.exports = () => ({
  apiUrl: process.env.API_URL || null,
  displayWarnings: process.env.DISPLAY_WARNINGS ?? null,
  isProduction: process.env.NODE_ENV === 'production',
  disableCache: process.env.DISABLE_CACHE,
});
