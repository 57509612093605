import { BackGroundFigures, SeoTags } from 'app/components';
import { ContentPreviews, Footer, Header } from 'app/sections';
import React from 'react';

export default function CoursesPage() {
  return (
    <>
      <SeoTags
        title="Eglibyte - Доступные онлайн курсы по программированию"
        description="Посмотрите наши доступные онлайн курсы по программированию, веб-разработке и IT навыкам."
      />

      <BackGroundFigures />
      <Header />
      <ContentPreviews />
      <Footer />
    </>
  );
}

CoursesPage.getPageData = async (/* location, params, props */) => {
  return (prevState) => ({
    ...prevState,
    // Add data here that will be added whenever the user navigates to this page
  });
};
