import React from 'react';

const ArrowSvg = () => {
  
  return (
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.66803 5.77086L0.137668 1.1702C-0.0458895 0.986642 -0.0458895 0.689825 0.137668 0.506267L0.414958 0.228977C0.598516 0.0454191 0.895333 0.0454191 1.07889 0.228977L5 4.22429L8.92111 0.232883C9.10467 0.0493248 9.40148 0.0493248 9.58504 0.232883L9.86233 0.510172C10.0459 0.69373 10.0459 0.990547 9.86233 1.1741L5.33197 5.77477C5.14841 5.95442 4.85159 5.95442 4.66803 5.77086Z"
        fill="#E7EAEF"/>
    </svg>
  );
};

export default ArrowSvg;