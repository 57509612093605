import React from 'react';
import classNames from "classnames";
import {
  AuthorInformation,
  CourseData,
  CourseFullInformation,
  CourseSectionList
} from "./components";

const Course = ({className}) => {
  
  const classes = classNames('course-section', (className ? ' ' + className : ''));
  
  return (
    <section className={classes}>
      <div className="container">
        <div className="course">
          <AuthorInformation className='course__author-information'/>
          <div className="course__content">
            <CourseFullInformation/>
            <CourseData/>
            <CourseSectionList/>
          </div>
          
        </div>
      </div>
    </section>
  );
};

export default Course;
