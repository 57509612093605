import ImagePlaceholder from 'app/images/ImagePlaceholder.jpg';
import React, { useCallback, useEffect, useState } from 'react';

function Image({ className, content, src, alt, title }, ref) {
  const [imageUrl, setImageUrl] = useState(src || ImagePlaceholder);
  const [imageAlt, setImageAlt] = useState(alt);
  const [imageTitle, setImageTitle] = useState(title);
  
  const classes = `image${className ? ' ' + className : ''}`;
  
  useEffect(() => {
    if (content) {
      setImageUrl(content.src || ImagePlaceholder);
      setImageAlt(content.alt || alt);
      setImageTitle(content.title || title);
    } else {
      setImageUrl(src || ImagePlaceholder);
      setImageAlt(alt);
      setImageTitle(title);
    }
  }, [content, src, alt, title]);
  
  const onError = useCallback(() => {
    setImageUrl(ImagePlaceholder);
  }, []);
  
  return <img className={classes} src={imageUrl} alt={imageAlt} title={imageTitle} ref={ref} onError={onError} />;
}

export default React.forwardRef(Image);
