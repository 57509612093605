import { compilePath } from 'app/helpers/utils';
import useMobx from 'app/mobx';
import keyBy from 'lodash/keyBy';
import { toJS } from 'mobx';
import { matchPath, useLocation } from 'react-router-dom';

/**
 * @returns the route from the initial data, corresponding to the given location.
 * Dynamic slugs in paths will be replaced with the corresponding params from the location
 */
function getRoute(routes, location) {
  for (let i = 0; i < routes.length; i++) {
    const match = matchPath(location.pathname, {
      exact: true,
      path: routes[i].path,
    });

    if (match) {
      return {
        ...routes[i],
        path: compilePath(routes[i].path, match.params),
      };
    }
  }

  return null;
}

export default function usePageData() {
  const { content } = useMobx();
  const location = useLocation();

  const page = {
    ...getRoute(toJS(content.routes), location),
    ...(content.pages[location.pathname] || {}),
  };

  const regions = Object.entries(content.regions)
    .map(([regionName, data]) => data ?? null)
    .filter(Boolean);

  const menus = keyBy(content.menus, 'slug');

  return { page, regions, menus, content };
}
