import { getAppStore } from 'app/helpers/utils';
import { get } from 'lodash';

const hydrate = (path, defaultValue = void 0) => {
  if (typeof window === 'undefined') return defaultValue;
  return get(getAppStore(), path, defaultValue);
};

// Loop through stores and check where auto hydration is enabled
export function hydrateStore(store, storeKey, ignoreKeys = []) {
  // Only hydrate on client
  if (typeof window === 'undefined') return;
  for (const storeProp of Object.keys(store)) {
    // Skip hydrating special keys
    if (ignoreKeys.includes(storeProp)) continue;
    if (typeof store[storeProp] === 'function') continue;
    store[storeProp] = hydrate(`${storeKey}.${storeProp}`, store[storeProp]);
  }
}

export default hydrate;
