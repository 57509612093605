import { LogoSvg } from 'app/svg';
import React from 'react';
import { NavLink } from 'react-router-dom';

export default function Logo({ className }) {
  // const homePagePath = usePathFromTemplate('home-page')
  const homePagePath = '/';

  const classes = `logo${className ? ' ' + className : ''}`;

  return (
    <NavLink to={homePagePath} aria-label="Eglibyte home page">
      <LogoSvg className={classes} />
    </NavLink>
  );
}
