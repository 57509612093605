import { fetchPageData } from 'app/api/requests';
import { action, makeObservable, observable, runInAction } from 'mobx';

/**
 * @property {Function} getMobx {@link createStore} retrieves root store
 */
class Content {
  @observable seo = {};
  @observable routes = [];
  @observable pages = {};
  @observable menus = [];
  @observable regions = [];
  @observable cookiesFields = {};
  @observable loading = false;

  constructor(hydrateStore) {
    hydrateStore(this);
    makeObservable(this);
  }

  @action setLoading(loading) {
    this.loading = loading;
  }

  @action setSeo(seo) {
    this.seo = seo;
  }

  @action setRoutes(routes) {
    this.routes = routes;
  }

  @action setRegions(regions) {
    this.regions = regions;
  }

  @action setMenus(menus) {
    this.menus = menus;
  }

  @action setCookiesFields(fields) {
    this.cookiesFields = fields;
  }

  @action
  async fetchPageData(path) {
    let data;
    try {
      data = (await fetchPageData(path)).data;
    } catch (error) {
      data = { error };
    }
    this.setSeo(data.seo || {});

    runInAction(() => {
      this.pages[path] = data;
    });
  }
}

export default Content;
