import React from 'react';

const ClockSolidSvg = () => {
  
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_351_1441)">
        <path
          d="M9.38574 0.451172C4.83691 0.451172 1.15137 4.13672 1.15137 8.68555C1.15137 13.2344 4.83691 16.9199 9.38574 16.9199C13.9346 16.9199 17.6201 13.2344 17.6201 8.68555C17.6201 4.13672 13.9346 0.451172 9.38574 0.451172ZM11.2816 12.0756L8.35312 9.94727C8.2502 9.8709 8.19043 9.75137 8.19043 9.62519V4.03711C8.19043 3.81797 8.36973 3.63867 8.58887 3.63867H10.1826C10.4018 3.63867 10.5811 3.81797 10.5811 4.03711V8.60918L12.6895 10.1432C12.8687 10.2727 12.9053 10.5217 12.7758 10.701L11.8395 11.9893C11.71 12.1652 11.4609 12.2051 11.2816 12.0756Z"
          fill="#E7EAEF"/>
      </g>
      <defs>
        <clipPath id="clip0_351_1441">
          <rect width="17" height="17" fill="white" transform="translate(0.885742 0.185547)"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default ClockSolidSvg;