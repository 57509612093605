import classNames from 'classnames';
import React from 'react';

function processArticlePart(part, index) {
  const { layout, attributes } = part;

  if (layout === 'article_text') {
    console.log({ layout: 'text', text: attributes.text });
    return <p key={index}>{attributes.text}</p>;
  } else if (layout === 'article_quote') {
    console.log({ layout: 'quote', text: attributes.text });
    return <blockquote key={index}>{attributes.text}</blockquote>;
  }
  // TODO: add article_code and article_image

  return null;
}

const Article = ({ className, post }) => {
  const classes = classNames('article', className ? ' ' + className : '');

  return (
    <div className={classes}>
      <h1>{post.title}</h1>
      <h2>{post.excerpt}</h2>

      {post.content?.map((part, index) => processArticlePart(part, index))}

      <ul>
        <li>Lorem ipsum dolor sit amet</li>
        <li>Lorem ipsum dolor sit amet, consectetur </li>
        <li>Lorem ipsum</li>
      </ul>
    </div>
  );
};

export default Article;
