import { useAppData } from '@optimistdigital/create-frontend/universal-react';
import { Button } from 'app/ui';
import React from 'react';
import { useHistory } from 'react-router-dom';

const messages = {
  404: 'Page not found',

  default: 'Server error',
};

const getStatusMessage = (status) => messages[status] || messages.default;

export default function ErrorPage({ status }) {
  const appData = useAppData();
  if (appData.serverContext) appData.serverContext.status = status;

  const history = useHistory();

  const statusMessage = getStatusMessage(status);

  return (
    <>
      <div className="error-page">
        <h1>
          {status} - {statusMessage}
        </h1>

        <Button text={'Go back'} onClick={history.goBack} />
      </div>
    </>
  );
}
