// Custom hook to get user language from browser or if user has selected a language in the app, it will return that language instead.
export default function useUserLanguage() {
  // const localStorageLanguage = localStorage.getItem('language');

  // if (localStorageLanguage) return localStorageLanguage;

  // const browserLanguage = navigator.language.split('-')[0];

  // return browserLanguage;

  return 'ru';
}
