import React from 'react';

const BookSolidSvg = () => {
  
  return (
    <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_351_1439)">
        <path
          d="M14.8857 12.1387V0.982422C14.8857 0.54082 14.5305 0.185547 14.0889 0.185547H3.19824C1.43848 0.185547 0.0107422 1.61328 0.0107422 3.37305V13.998C0.0107422 15.7578 1.43848 17.1855 3.19824 17.1855H14.0889C14.5305 17.1855 14.8857 16.8303 14.8857 16.3887V15.8574C14.8857 15.6084 14.7695 15.3826 14.5902 15.2365C14.4508 14.7252 14.4508 13.2676 14.5902 12.7563C14.7695 12.6135 14.8857 12.3877 14.8857 12.1387ZM4.26074 4.63477C4.26074 4.5252 4.35039 4.43555 4.45996 4.43555H11.499C11.6086 4.43555 11.6982 4.5252 11.6982 4.63477V5.29883C11.6982 5.4084 11.6086 5.49805 11.499 5.49805H4.45996C4.35039 5.49805 4.26074 5.4084 4.26074 5.29883V4.63477ZM4.26074 6.75977C4.26074 6.6502 4.35039 6.56055 4.45996 6.56055H11.499C11.6086 6.56055 11.6982 6.6502 11.6982 6.75977V7.42383C11.6982 7.5334 11.6086 7.62305 11.499 7.62305H4.45996C4.35039 7.62305 4.26074 7.5334 4.26074 7.42383V6.75977ZM12.6744 15.0605H3.19824C2.61055 15.0605 2.13574 14.5857 2.13574 13.998C2.13574 13.4137 2.61387 12.9355 3.19824 12.9355H12.6744C12.6113 13.5033 12.6113 14.4928 12.6744 15.0605Z"
          fill="#E7EAEF"/>
      </g>
      <defs>
        <clipPath id="clip0_351_1439">
          <rect width="14.875" height="17" fill="white" transform="translate(0.0107422 0.185547)"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default BookSolidSvg;