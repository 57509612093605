import { Field } from 'formik';
import _ from 'lodash';
import React from 'react';

export default function Input(props) {
  const { name, className, onBlur, values, touched, errors, label, disabled, as } = props;

  const classes = `field-wrap${className ? ' ' + className : ''} ${
    touched[name] && errors[name] ? ' field-wrap-error' : ''
  }`;

  return (
    <div className={classes}>
      <Field id={name} value={_.get(values, name, '')} placeholder=" " {...{ as, name, disabled, onBlur }} />
      <label aria-labelledby={name} htmlFor={name}>
        {touched[name] && errors[name] ? errors[name] : label}
      </label>
    </div>
  );
}
