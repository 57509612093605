import React, {useState} from 'react';
import classNames from "classnames";

const SlideTabs = ({className, tabs, activeTabIndex = 0, onChange}) => {
  const classes = classNames('slide-tabs', (className ? ' ' + className : ''));
  
  const [activeTab, setActiveTab] = useState(activeTabIndex)
  
  const backgroundWidth = 100 / Object.values(tabs).length
  const backgroundTranslate = 100 * (Math.max(activeTab, 0))
  
  const changeActiveTab = (index) => {
    onChange?.(Object.keys(tabs)[index])
    setActiveTab(index)
  }
  
  return (
    <div className={classes}>
      <div style={{width: `${backgroundWidth}%`, transform: `translateX( ${backgroundTranslate}%)`}}
           className="slide-tabs__background"></div>
      {Object.values(tabs).map((tab, key) => {
        return <span onClick={() => changeActiveTab(key)} key={key}
                     className={classNames('slide-tabs__tab', (activeTab === key && 'active'))}>
          {tab}
        </span>
      })}
    </div>
  );
};

export default SlideTabs;
