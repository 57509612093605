import React from 'react';
const TelegramSvg = (props) => {
  const {className} = props;
  
  const classes = `telegram-svg${className ? ' ' + className : ''}`;
  
  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" version="1.1"
         width="512" height="512" x="0" y="0" viewBox="0 0 511.981 511.981">
        <path d="M504.143 55.033c-6.249-7.672-15.628-11.898-26.412-11.898-5.818 0-11.982 1.218-18.32 3.619L25.956 210.984C2.909 219.718-.193 232.865.009 239.924c.201 7.024 4.012 19.878 27.21 27.298l91.925 33.687 48.447 110.897c4.87 15.338 15.046 26.098 28.034 29.588a35.083 35.083 0 0 0 9.13 1.201c10.548 0 21.348-4.645 30.675-13.405l40.138-37.71 97.935 67.408c8.82 6.518 18.417 9.96 27.775 9.959 18.994-.001 34.143-13.905 38.594-35.423l70.891-342.863c2.913-14.091.562-26.708-6.62-35.528zM149.609 295.704 274.7 228.623l-87.18 79.969a14.984 14.984 0 0 0-4.838 11.038c-.006.154-.025.304-.026.458l-.301 50.572zm65.281 111.62a28.536 28.536 0 0 1-2.768 2.298l.367-61.561 37.791 26.012zM481.384 84.486l-70.89 342.862c-.715 3.457-3.045 11.499-9.218 11.499-2.883 0-6.459-1.484-10.069-4.18a13.941 13.941 0 0 0-.471-.337l-107.969-74.315-.044-.03-61.044-42.017 161.255-147.916a15 15 0 0 0 2.092-19.736 15 15 0 0 0-19.321-4.537l-236.544 126.85-91.927-33.687a12.01 12.01 0 0 0-.202-.073L470.041 74.808c3.649-1.383 6.196-1.673 7.69-1.673.741 0 2.531.082 3.149.842.793.973 1.791 4.282.504 10.509z"/>
    </svg>
  );
};

export default TelegramSvg;