import React from 'react';
import classNames from "classnames";
import {Button, Image} from "app/ui";
import {GithubSvg} from "app/svg";
import {NavLink} from "react-router-dom";
import image from 'app/images/roman-egliens.png'

const AuthorInformation = ({className}) => {
  
  const classes = classNames('author-information', (className ? ' ' + className : ''));
  
  return (
    <div className={classes}>
      <Image src={image} className='author-information__image'/>
      <div className="author-information__wrapper">
        <span className="author-information__instructor">
          <span>
            Твой инструктор на сегодня
          </span>
        </span>
        <h3 className="author-information__name">
          Роман Эглиенс
        </h3>
        <ul className="author-information__socials">
          <li className="author-information__social">
            <NavLink to='/'>
              <GithubSvg/>
            </NavLink>
          </li>
          <li className="author-information__social">
            <NavLink to='/'>
              <GithubSvg/>
            </NavLink>
          </li>
        </ul>
        <p className="author-information__bio">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
          standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make
          a type specimen book.
        </p>
        <Button className='author-information__button outlined' text='Больше курсов от меня'/>
        <Button className='author-information__button' text='Посети мой сайт'/>
      </div>
      
    </div>
  );
};

export default AuthorInformation;
