import { useAppData } from '@optimistdigital/create-frontend/universal-react';
import templates from 'app/config/templates';
import ErrorPage from 'app/pages/ErrorPage';
import { observer } from 'mobx-react';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

function Pages() {
  const {
    pageData: { routes },
  } = useAppData();

  if (!routes) return null;

  return (
    <Switch>
      {routes.map((route) => {
        const routeComponent = route.component || templates[route.template];
        if (!routeComponent) return null;

        return <Route key={route.path} exact {...route} component={routeComponent} />;
      })}

      <Route>
        <ErrorPage status={404} />
      </Route>
    </Switch>
  );
}

export default observer(Pages);
