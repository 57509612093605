import React from 'react';
import classNames from "classnames";
import {ContentPreview} from "../index";

const ContentPreviewListLarge = ({className, content}) => {
  const lgClasses = classNames('content-preview-list-large', 'content-preview-list-large__lg', (className ? ' ' + className : ''));
  const mdClasses = classNames('content-preview-list-large', 'content-preview-list-large__md', (className ? ' ' + className : ''));
  const smClasses = classNames('content-preview-list-large', 'content-preview-list-large__sm', (className ? ' ' + className : ''));
  
  
  return (
    <>
      <div className={lgClasses}>
        {content && content.length <= 4 ? (
          content.map(el => (
            <ContentPreview
              key={el.id}
              content={el}
            />
          ))
        ) : <>
          <ContentPreview content={content[0]} size='vertical'/>
          
          <div className="content-preview-list-large__wrapper">
            <div className="content-preview-list-large__smalls">
              {content[1] && <ContentPreview content={content[1]}/>}
              {content[2] && <ContentPreview content={content[2]}/>}
            </div>
            
            {content[3] && <ContentPreview content={content[3]} size='horizontal'/>}
          </div>
        </>}
        
      
      </div>
      
      <div className={mdClasses}>
        {content.map((el, index) => (
          <ContentPreview
            key={el.id}
            content={el}
            size={(index + 1) % 3 === 0 ? 'horizontal' : 'small'}
          />
        ))}
      </div>
      
      <div className={smClasses}>
        {content.map(el => <ContentPreview key={el.id} content={el} size='vertical'/>)}
      </div>
    </>
  );
};

export default ContentPreviewListLarge;
