import React from 'react';
import classNames from "classnames";
import {ClockSolidSvg} from "app/svg";

const CourseEpisode = ({className}) => {
  
  const classes = classNames('course-episode', (className ? ' ' + className : ''));
  
  return (
    <div className={classes}>
      <div className="course-episode__count">
        05
      </div>
      <div className="course-episode__info">
        <h4 className="course-episode__title">
          Assets, Layouts and Fancy Selects
        </h4>
        <p className="course-episode__description">
          Начнем с извлечения всех изображений и активов для дизайна блога. Затем мы начнем писать первый проект общей
          HTML-верстки. Для этого нам нужно будет потратить несколько минут на то, чтобы понять, как стилизовать
          причудливое меню выбора.
        </p>
        <div className="course-episode__duration">
          <ClockSolidSvg/>
          <span>
            3ч 43м
          </span>
        </div>
      </div>
    </div>
  );
};

export default CourseEpisode;
