import { cacheResponse } from 'app/helpers/cache';
import logWarning from 'app/helpers/log';
import axios from 'axios';

let axiosInstance;

class RequestError extends Error {
  originalError;
  url;
  data;

  constructor(originalError, url, data) {
    super();
    this.statusCode = originalError.statusCode || originalError.response?.status;
    this.message = originalError.message;
    this.originalError = originalError;
    this.url = url;
    this.data = data;
  }
}

// Return correct URL on server and client respectively
export function getApiUrl() {
  if (__TARGET__ === 'node') return process.env.API_URL || '';

  const apiUrl = window.__OCF_APP_DATA__.apiUrl;
  if (apiUrl == null) throw new Error('Invalid API URL');

  return apiUrl;
}

export default function api(config) {
  if (!axiosInstance) {
    axiosInstance = axios.create({
      timeout: 60000,
      baseURL: getApiUrl(),
      ...config,
    });
  }

  function consoleWarnErrors(err, url, data = '') {
    const status = err.response?.status;
    // No need to log 404s
    if (!status || status !== 404) {
      logWarning(`${err.message} to ${url}`, data);
    }
    throw new RequestError(err, url, data);
  }

  return {
    post: async (endpoint, data) =>
      axiosInstance.post(endpoint, data).catch((e) => consoleWarnErrors(e, endpoint, data)),
    get: async (endpoint, data = null, { cache = 0 } = {}) => {
      const onError = (e) => consoleWarnErrors(e, endpoint, data);
      const request = async () => axiosInstance.get(endpoint, { params: data }).catch(onError);
      if (cache && !isNaN(cache) && !data) {
        return await cacheResponse(endpoint, cache, request);
      } else if (data) {
        console.warn('Requests with query params are not cached!');
      }
      return await request();
    },
  };
}
