import Cookies from 'js-cookie';
import { observe, runInAction, toJS } from 'mobx';

// Don't store because they're too big and will break the cookie. (Cookies have max length)
const IGNORED_KEYS = [];

export const storable = (target, name) => {
  target.storables = target.storables ? [...target.storables, name] : [name];
};

export default function saveToCookie(store, cookieName) {
  const storables = store.storables;
  observe(store, () => {
    const parsedStore = toJS(store);
    const storedValues = Object.entries(parsedStore).reduce((previous, [name, value]) => {
      if (!storables?.includes(name)) return previous;

      IGNORED_KEYS.forEach((k) => delete value?.[k]);

      return {
        ...previous,
        [name]: value,
      };
    }, {});

    Cookies.set(cookieName, JSON.stringify(storedValues), { expires: 1 });
  });
}

export function initFromCookie(cookieName, store, cookies) {
  if (!cookies) return store;
  const cookieValue = cookies[cookieName];
  if (!cookieValue) return store;

  try {
    runInAction(() => {
      Object.entries(JSON.parse(cookieValue)).forEach(([key, value]) => {
        store[key] = value;
      });
    });

    return store;
  } catch (e) {
    console.log(`Failed to init store from cookie`);
    return store;
  }
}
