import { fetchInitialContent } from 'app/api/requests';
import logWarning from 'app/helpers/log';
import { createRoutes } from 'app/helpers/utils';

/**
 * Called in react/App.js
 *
 * Populate mobx with initial data before first render.
 * This function should be called once on the server side.
 */
export async function hydrateInitialStore(store) {
  try {
    // Cache initial response for 60 seconds (cache is disabled in dev)
    const response = await fetchInitialContent(60000);

    if (!response) {
      logWarning('No initial content returned from CMS!');
    } else {
      await hydrateContent(store.content, response);
    }
  } catch (e) {
    logWarning('Initial store hydration failed on server!', e);
  }

  return store;
}

async function hydrateContent(content, initialData) {
  content.setRoutes(createRoutes(initialData?.routes || []));
  content.setSeo(initialData?.seo || {});
  content.setMenus(initialData?.menus || {});
  content.setRegions(initialData?.regions || {});
}
