import React from 'react';
import {GrayLogoSvg} from "app/svg";
import {Button, Image} from "app/ui";
import TagList from "../TagList";
import PublicationDate from "../PublicationDate";
import ViewsCount from "../ViewsCount";
import classNames from "classnames";

const ContentPreview = ({className, content, size = 'small' , color = 'yellow'}) => {
  
  const classes = classNames(
    'content-preview',
    (className ? ' ' + className : ''),
    size,
    color
  );
  
  
  
  return (
    <div className={classes}>
      <div className="content-preview__background"></div>
      <div className="content-preview__overflow"></div>
      <GrayLogoSvg className='content-preview__gray-logo-svg'/>
      <div className="content-preview__img-wrapper">
        <Image content={content.image}/>
      </div>
      <div className="content-preview__content">
        <div className="content-preview__content-head">
          <TagList tags={content.tags} className='content-preview__tags'/>
          <ul className="content-preview__info">
            <li>
              <PublicationDate timestamp={content.timestamp}/>
            </li>
            <li>
              <ViewsCount count={content.views}/>
            </li>
          </ul>
        </div>
        
        <h3 className="content-preview__title">
          {content.title}
        </h3>
        <p className="content-preview__description">
          {content.description}
        </p>
        <Button className='content-preview__button outlined' to={content.url} text='Начать'/>
      </div>
    </div>
  )
};


export default ContentPreview;
