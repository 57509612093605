import React from 'react';
import classNames from "classnames";
import {ContentPreview} from "../index";

const ContentPreviewListSmall = ({className, content}) => {
  const lgClasses = classNames('content-preview-list-small', 'content-preview-list-small__lg', (className ? ' ' + className : ''));
  const mdClasses = classNames('content-preview-list-small', 'content-preview-list-small__md', (className ? ' ' + className : ''));
  const smClasses = classNames('content-preview-list-small', 'content-preview-list-small__sm', (className ? ' ' + className : ''));
  return (
    <>
      <div className={lgClasses}>
        {content.map(el => <ContentPreview key={el.id} content={el}/>)}
      </div>
      
      <div className={mdClasses}>
        {content.map((el, index) => (
          <ContentPreview key={el.id} content={el} size={index % 3 === 0 ? 'horizontal' : 'vertical'} />
        ))}
      </div>
      
      <div className={smClasses}>
        {content.map(el => <ContentPreview key={el.id} content={el} size='vertical'/>)}
      </div>
    </>
    
  );
};

export default ContentPreviewListSmall;
