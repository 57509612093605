import { getAppConfig } from 'app/helpers/utils';

const cacheStore = {};

export async function cacheResponse(key, expires, request) {
  const { isProduction, disableCache } = getAppConfig();

  if (!isProduction || disableCache || typeof window !== 'undefined') {
    return await request();
  }

  if (!cacheStore[key] || (cacheStore[key] && cacheStore[key].expires <= Date.now())) {
    const data = await request();
    if (data) {
      cacheStore[key] = {
        expires: Date.now() + expires,
        data,
      };
    }
  }

  return cacheStore[key].data;
}
