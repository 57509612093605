import React from "react";

const DebugGrid = (props) => {
  const { COLUMNS = 12, hide = true, big } = props;

  
  let colsHtml = "";

  for (let i = 0; i < COLUMNS; i++) {
    colsHtml += "<div></div>";
  }

  if (!hide) {
    return (
      <div className={big ? 'debugGrid-big' : 'debugGrid'}>
        <div>
          <div dangerouslySetInnerHTML={{
            __html: colsHtml,
          }}></div>
        </div>
      </div>
    );
  } 
    return null;
  
};

export default DebugGrid;