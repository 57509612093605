import React from 'react';
import {Footer, Header, ContentPreviews} from "app/sections";

export default function BlogPage() {
  const contentPreviewList = [
    {
      id: 1,
      type: 'small',
      content: [
        {
          id: 1,
          tags: ['html', 'css'],
          title: 'Основы html 1',
          description: 'Слова и буквы были заменены добавлением или сокращением элементов, поэтому будет совсем неразумно пытаться передать содержание; это не гениально. Слова и буквы были заменены добавлением или сокращением элементов, поэтому будет.',
          timestamp: 1668975307,
          views: 6505,
          url: '/post',
          image: {
            alt: 'Test alt',
            title: 'none',
            url: '#'
          }
        },
        {
          id: 2,
          tags: ['html', 'css'],
          title: 'Основы css 2',
          description: 'Слова и буквы были заменены добавлением или сокращением элементов, поэтому будет совсем неразумно пытаться передать содержание; это не гениально. Слова и буквы были заменены добавлением или сокращением элементов, поэтому будет.',
          timestamp: 1668975307,
          views: 6505,
          url: '/post',
          image: {
            alt: 'Test alt',
            title: 'none',
            url: '#'
          }
        },
        {
          id: 3,
          tags: ['html', 'css'],
          title: 'Основы html 3',
          description: 'Слова и буквы были заменены добавлением или сокращением элементов, поэтому будет совсем неразумно пытаться передать содержание; это не гениально. Слова и буквы были заменены добавлением или сокращением элементов, поэтому будет.',
          timestamp: 1668975307,
          views: 6505,
          url: '/post',
          image: {
            alt: 'Test alt',
            title: 'none',
            url: '#'
          }
        },
        {
          id: 4,
          tags: ['html', 'css'],
          title: 'Основы html 4',
          description: 'Слова и буквы были заменены добавлением или сокращением элементов, поэтому будет совсем неразумно пытаться передать содержание; это не гениально. Слова и буквы были заменены добавлением или сокращением элементов, поэтому будет.',
          timestamp: 1668975307,
          views: 6505,
          url: '/post',
          image: {
            alt: 'Test alt',
            title: 'none',
            url: '#'
          }
        },
        {
          id: 5,
          tags: ['html', 'css'],
          title: 'Основы html 5',
          description: 'Слова и буквы были заменены добавлением или сокращением элементов, поэтому будет совсем неразумно пытаться передать содержание; это не гениально. Слова и буквы были заменены добавлением или сокращением элементов, поэтому будет.',
          timestamp: 1668975307,
          views: 6505,
          url: '/post',
          image: {
            alt: 'Test alt',
            title: 'none',
            url: '#'
          }
        },
        {
          id: 6,
          tags: ['html', 'css'],
          title: 'Основы html 6',
          description: 'Слова и буквы были заменены добавлением или сокращением элементов, поэтому будет совсем неразумно пытаться передать содержание; это не гениально. Слова и буквы были заменены добавлением или сокращением элементов, поэтому будет.',
          timestamp: 1668975307,
          views: 6505,
          url: '/post',
          image: {
            alt: 'Test alt',
            title: 'none',
            url: '#'
          }
        },
      ]
    }
  ]
  return <>
    <Header/>
    <ContentPreviews content={contentPreviewList}/>
    <Footer/>
  </>;
}

BlogPage.getPageData = async (/* location, params, props */) => {
  return prevState => ({
    ...prevState,
    // Add data here that will be added whenever the user navigates to this page
  });
};
