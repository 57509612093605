import React from 'react';
import classNames from "classnames";
import {CourseEpisode} from "../index";
import {ArrowSvg} from "app/svg";

const CourseSectionBlock = ({className, open}) => {
  
  const classes = classNames('course-section-block', (className ? ' ' + className : ''));
  
  return (
    <div className={classes}>
      <div className="course-section__header">
        <span className="course-section__count">
          <span className="course-section__count-name">
            Секция
          </span>
          <span className="course-section__count-number">
            1
          </span>
        </span>
        <h3 className="course-section__title">
            Основы HTML
        </h3>
        <span className="course-section__arrow">
          <ArrowSvg/>
        </span>
      </div>
      
      {open && <div className="course-section__body">
        <CourseEpisode/>
        <CourseEpisode/>
        <CourseEpisode/>
      </div>}
      
    </div>
  );
};

export default CourseSectionBlock;
