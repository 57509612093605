import {sendFeedback} from 'app/api';
import useUserLanguage from 'app/hooks/useUserLanguage';
import {Button, Input, SlideTabs} from 'app/ui';
import classNames from 'classnames';
import {Form, Formik} from 'formik';
import React, {useCallback} from 'react';
import {Link} from 'react-router-dom';
import * as Yup from 'yup';

const ContactMethods = {
  PHONE: 'phone',
  EMAIL: 'email',
  TELEGRAM: 'telegram'
}
const InitialActiveTab = ContactMethods.PHONE
const InitialActiveTabIndex = Object.values(ContactMethods).indexOf(InitialActiveTab)

const contactTabsNames = {[ContactMethods.PHONE]: 'Позвонить', [ContactMethods.EMAIL]: "Написать", [ContactMethods.TELEGRAM]: 'Телеграм'}

const initialValues = {
  name: '',
  phone: '',
  email: '',
  contactMethod: InitialActiveTab
};

const buttonText = {
  [ContactMethods.PHONE]: 'Позвоните мне',
  [ContactMethods.EMAIL]: 'Написать мне',
  [ContactMethods.TELEGRAM]: 'Связаться',
}

const validationSchema = Yup.object({
  name: Yup.string().min(3, 'Должно быть более 3 символов').required('Обязательно'),
  phone: Yup.string()
    .when('contactMethod', {
      is: ContactMethods.PHONE,
      then: (schema) => schema
        .matches(/^(\+|[0-9])[0-9 -]+[0-9]$/, 'Номер телефона недействителен')
        .required('Обязательно'),
      otherwise: schema => schema.nullable(),
    }),
  email: Yup.string()
    .when('contactMethod', {
      is: ContactMethods.EMAIL,
      then: (schema) => schema
        .required('Обязательно'),
      otherwise: schema => schema.nullable(),
    }),
  contactMethod: Yup.mixed().oneOf(Object.values(ContactMethods)).required()
});


export default function SmallForm({className}) {
  const classes = classNames('small-form', className ? ' ' + className : '');
  
  const userLanguage = useUserLanguage();
  
  const handleFormBlur = useCallback((event, values, handleBlur) => {
    handleBlur(event);
  }, []);
  
  const saveChanges = useCallback(
    async (event, values, {resetForm}) => {
      values.form_id = 'small_form';
      values.locale = userLanguage;
      
      const response = await sendFeedback(values);
      
      if (response.status === 200) {
        resetForm();
      }
    },
    [userLanguage]
  );
  
  return (
    <>
      <h4 id="contact" className="small-form__title">
        Ты в одном шаге от профессии:
      </h4>
      
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, {resetForm}) => saveChanges(null, values, {resetForm})}
      >
        {({touched, errors, handleBlur, values, handleChange, setFieldValue}) => {
          return (
            <Form className={classes}>
              
              <SlideTabs tabs={contactTabsNames} activeTabIndex={InitialActiveTabIndex} onChange={(value) => setFieldValue('contactMethod', value)}/>
              {values.contactMethod !== ContactMethods.TELEGRAM && <Input
                handleChange={handleChange}
                onBlur={(e) => handleFormBlur(e, values, handleBlur)}
                touched={touched}
                errors={errors}
                values={values}
                type="text"
                name="name"
                autocomplete="name"
                label="Имя"
              />}
              {values.contactMethod === ContactMethods.PHONE && <Input
                handleChange={handleChange}
                onBlur={(e) => handleFormBlur(e, values, handleBlur)}
                touched={touched}
                errors={errors}
                values={values}
                type="text"
                name="phone"
                autocomplete="tel"
                label="Телефон"
              />}
              {values.contactMethod === ContactMethods.EMAIL && <Input
                handleChange={handleChange}
                onBlur={(e) => handleFormBlur(e, values, handleBlur)}
                touched={touched}
                errors={errors}
                values={values}
                type="email"
                name="email"
                autocomplete="email"
                label="Почта"
              />}
              
              {values.contactMethod !== ContactMethods.TELEGRAM && <Button type="submit" className="small-form__button" actionName="save"
                      text={buttonText[values.contactMethod]}/>}
              
              {values.contactMethod === ContactMethods.TELEGRAM && <a href="https://t.me/+37258269828" target='_blank' className="button small-form__button" aria-label="Telegram link">
                <span>{buttonText[values.contactMethod]}</span>
              </a>}
              
              <p className="small-form__policy">
                Нажимая на эту кнопку я даю согласие на обработку своих{' '}
                <Link to="#privacy-policy">персональных данных</Link>.
              </p>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
